$great-design-system-grey-border: #7d8e96;

.great-ds-border-bottom-grey {
  border-bottom: 1px solid $great-design-system-grey-border;
}

.buy-from-the-uk-logo {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.buy-from-the-uk-logo-container {
    background-color: #fff;
    border: 1px solid $great-design-system-grey-border;
    width: 180px;
    height: 180px;
    padding: 15px;
}

.case-study-image {
    max-width: 300px;
    display: block;
}

.great-heading-link::after {
    top: 2px;
}
